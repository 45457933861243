// Shopify
export default function shopify() {
	const shopifyIntegration = () => {
		if (window.ShopifyBuy) {
			return false; // Shopify already initialized
		}
		const scriptURL =
			'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
		if (window.ShopifyBuy) {
			if (window.ShopifyBuy.UI) {
				ShopifyBuyInit();
			} else {
				loadScript();
			}
		} else {
			loadScript();
		}
		function addToCart(product) {
			if (
				typeof dataLayer !== 'undefined' &&
				product.typeKey === 'product'
			) {
				window.dataLayer.push({
					event: 'sendEvent',
					eventCategory: 'Product Sales',
					eventAction: 'Buy Now',
					eventLabel: product.model.title
				});
			}
		}
		function loadScript() {
			const script = document.createElement('script');
			script.async = true;
			script.src = scriptURL;
			(
				document.getElementsByTagName('head')[0] ||
				document.getElementsByTagName('body')[0]
			).appendChild(script);
			script.onload = ShopifyBuyInit;
		}
		function ShopifyBuyInit() {
			const client = ShopifyBuy.buildClient({
				domain: 'royal-salute-scotch-whisky.myshopify.com',
				storefrontAccessToken: '66d6197dc53486d8927ce8abdea67b88'
			});
			// Documentation is available here:
			// @link https://shopify.github.io/buy-button-js/
			ShopifyBuy.UI.onReady(client).then((ui) => {
				const shopifyButtonArray =
					document.querySelectorAll('.buy-now-wrapper');
				for (
					let index = 0;
					index < shopifyButtonArray.length;
					index++
				) {
					const shopifyButton = shopifyButtonArray[index];
					ui.createComponent('product', {
						id: shopifyButton.dataset.pim,
						node: shopifyButton,
						moneyFormat: '%C2%A3%7B%7Bamount%7D%7D',
						options: {
							product: {
								iframe: false,
								contents: {
									button: true,
									buttonWithQuantity: false,
									img: false,
									title: false,
									price: false
								},
								classes: {
									button: 'ctbuy-button cream-hover'
								},
								text: {
									button: shopifyButton.dataset.label
								},
								events: {
									addVariantToCart(product) {
										addToCart(product);
									}
								}
							},
							productSet: {
								styles: {
									products: {
										'@media (min-width: 601px)': {
											'margin-left': '-20px'
										}
									}
								}
							},
							modalProduct: {
								iframe: false,
								contents: {
									img: false,
									imgWithCarousel: true,
									button: true,
									buttonWithQuantity: false
								},
								styles: {
									product: {
										'@media (min-width: 601px)': {
											'max-width': '100%',
											'margin-left': '0px',
											'margin-bottom': '0px'
										}
									},
									button: {
										'font-size': '16px',
										'padding-top': '16px',
										'padding-bottom': '16px',
										':hover': {
											'background-color': '#33aba7'
										},
										'background-color': '#39beb9',
										':focus': {
											'background-color': '#33aba7',
											outline: 'none'
										}
									},
									quantityInput: {
										'font-size': '16px'
									}
								},
								text: {
									button: 'Add to cart'
								}
							},
							cart: {
								iframe: false,
								styles: {
									button: {
										'font-size': '16px',
										'padding-top': '16px',
										'padding-bottom': '16px',
										'font-weight': 'bold',
										cursor: 'pointer',
										'text-decoration': 'none',
										color: 'white',
										'background-color': '#253646',
										'border-color': '253646',
										':hover': {
											'background-color': '#253646'
										},
										':focus': {
											'background-color': '#253646',
											border: 'none',
											outline: 'none'
										}
									}
								},
								text: {
									title: 'Shopping Basket',
									total: 'Subtotal',
									notice: 'Please note that these products are sold by Mail Marketing UK Limited. For full information please refer to our Terms and Conditions. Shipping and discount codes to be added at checkout. Following Brexit, orders to the EU will be subject to additional custom clearance. DHL may contact you for further information on your order prior to delivery which will need to be provided locally. If you need any assistance, please contact royalsalute@mailmarkuk.com',
									button: 'Checkout'
								},
								events: {
									openCheckout(cart) {
										if (
											cart.model.attrs.webUrl.value &&
											typeof ga !== 'undefined' && // extra check, in case browsers are blocking tracking
											typeof ga.getAll === 'function' &&
											typeof ga.getAll()[0] !==
												'undefined'
										) {
											// Adds google analytics tracking code to cart URL for tracking of session
											const clientId = ga
												.getAll()[0]
												.get('linkerParam');
											if (clientId.length > 0) {
												cart.model.attrs.webUrl.value += `&${clientId}`;
											}
										}
									}
								}
							},
							toggle: {
								iframe: false,
								styles: {
									toggle: {
										'background-color': '#ac843c',
										':hover': {
											'background-color': '#ded5c6'
										},
										':focus': {
											'background-color': '#ded5c6',
											border: 'none',
											outline: 'none'
										}
									},
									count: {
										'font-size': '16px'
									}
								},
								events: {
									afterRender() {
										const toggleElement =
											document.getElementsByClassName(
												'shopify-buy-frame--toggle'
											)[0];
										toggleElement.setAttribute(
											'tabindex',
											0
										);
										toggleElement.setAttribute(
											'role',
											'button'
										);
										toggleElement.addEventListener(
											'keyup',
											(event) => {
												if (
													event.keyCode === 32 ||
													event.keyCode === 13
												) {
													event.target.children[0].click();
												}
											}
										);
									}
								}
							}
						}
					});
				}
			});
		}
	};

	const skipShopify = () => {
		// @link https://irishdistillers.atlassian.net/browse/RS-267
		const skipShopifyForSlug = '21-year-old-the-signature-blend';
		const language =
			window.navigator.userLanguage || window.navigator.language;
		return (
			language &&
			language.toLowerCase() === 'de-de' &&
			document.querySelector('link[rel=canonical]') &&
			document
				.querySelector('link[rel=canonical]')
				.getAttribute('href')
				.includes(`/${skipShopifyForSlug}/`)
		);
	};

	if (document.querySelector('.shopify-integration') && !skipShopify()) {
		const shopifyCallback = (entries, observer) => {
			entries.forEach((entry) => {
				// Each entry describes an intersection change for one observed
				if (entry.isIntersecting) {
					shopifyIntegration();
					shopifyObserver.unobserve(shopify);
				}
			});
		};
		const shopifyObserver = new IntersectionObserver(shopifyCallback);
		const shopify = document.querySelector('.buy-now-wrapper');
		shopifyObserver.observe(shopify);
		// Initializing Shopify with a delay, in case the user doesn't scroll
		// This is useful to show the floating cart toggle and to ensure the Shopify Buy Now
		// is not delayed as the user scrolls down.
		setTimeout(() => shopifyIntegration(), 3500);
	}
}
