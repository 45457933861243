import {resizeDebouncer} from '@pernod-ricard-global-cms/jsutils';
function calculateHeaderHeight(block) {
	const headerHeight = block.offsetHeight;
	document.documentElement.style.setProperty(
		'--global-site-header-height',
		`${headerHeight}px`
	);
}
export default function siteHeaderJs(options = {}) {
	try {
		const {block} = options;

		const documentBody = document.body;
		const siteHeader = document.querySelector('.site-header');
		const navContainer = document.querySelector('.header-nav');
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const itemsWithChildren = navContainer.querySelectorAll(
			'li.menu-item-has-children'
		);

		window.onscroll = function () {
			scrollFunction();
		};

		const scrollFunction = () => {
			if (
				document.body.scrollTop > 120 ||
				document.documentElement.scrollTop > 50
			) {
				siteHeader.classList.add('site-header--scrolled');
			} else {
				siteHeader.classList.remove('site-header--scrolled');
			}
		};

		const closeSubnavs = () => {
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));
			documentBody.classList.remove('mobile-menu-active');
		};

		hamburgerWrapper.addEventListener('click', function () {
			if (window.innerWidth < 1024) {
				hamburger.classList.toggle('active');
				hamburgerBars.forEach((item) => {
					item.classList.toggle('active');
				});
				navContainer.classList.toggle('active');
				documentBody.classList.toggle('mobile-menu-active');
			}
		});

		itemsWithChildren.forEach((item) => {
			let childToggle = item.querySelector('.header-nav__toggle');
			childToggle.addEventListener('click', function (e) {
				item.classList.toggle('active');
			});

            if (item.querySelector('.current-menu-item')) {
                item.classList.toggle('active');
            }
		});
		calculateHeaderHeight(block);
		resizeDebouncer(() => calculateHeaderHeight(block), 250, true);
	} catch (error) {
		console.error(error);
	}
}
